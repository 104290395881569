<script>
import {Line} from "vue-chartjs"
export default {
	extends : Line ,
	props : {
		width : {
			type : Number,
			default : undefined
		} ,
		height : {
			type : Number,
			default : undefined,
		} ,
		hideLegend : {
			type : Boolean,
			default : false,
		}
	},
	methods: {
		applyChartData(chartData=null,chartOptions={}) {
			if (chartData) {
				const options = {
					responsive : true,
					legend : this.hideLegend ? false : {position : 'bottom'} ,
					animation: {
						animateScale: true,
						animateRotate: true
					} ,
					...chartOptions,
				}
				this.renderChart(chartData,options)
			}
		}
	}
}
</script>
