<i18n src="@i18n/ticket/ticket.th.yaml" locale="th" lang="yaml"/>
<i18n locale="th" lang="yaml" >
table.drone.serial_no : "S/N : {serial_no}"
ticket_table.field.problem_type : "รูปแบบ"
ticket_table.field.resolved_result : "ผลประเมิน"
</i18n>

<template>
	<a-table
		v-bind="$attrs"
		class="mytable mytable-scroll ticket-table"
		size="small"
		:pagination="pagination"
		v-on="$listeners">
		<a-table-column
			:title="$t('common.table.column.no')"
			align="center"
			class="column-row-no">
			<template v-slot="text,record,index">
				{{index + 1 + ((pagination.current - 1) * pagination.pageSize)}}
			</template>
		</a-table-column>
		<a-table-column
			key="ticket_no" :sorter="true"
			:title="$t('ticket.field.ticket_no')"
			data-index="ticket.ticket_no"
			:width="115">
			<template v-slot="text,record">
				<my-router-link name="ticket/view" :param-value="record.id">
					{{text}}
				</my-router-link>
			</template>
		</a-table-column>
		<a-table-column
			v-if="!hideColumnDrone"
			key="drone.name" :sorter="true"
			:title="$t('ticket.field.drone')"
			:width="125"
			align="center"
			data-index="droneData">
			<template v-slot="droneData">
				<span v-if="!droneData.id">
					{{$t('ticket.field.general_ticket')}}
				</span>
				<my-router-link v-else name="drone/view" :param-value="droneData.drone.serial_no" :new-window="true" param-name="serial_no">
					{{droneData.drone.name}}
					<span class="drone-serial-no">({{droneData.drone.serial_no}})</span>
				</my-router-link>
			</template>
		</a-table-column>
		<a-table-column
			key="topic" :sorter="true"
			class="ticket-detail-col"
			:title="$t('common.table.column.detail')">
			<template v-slot="{ ticket }">
				<div v-trim class="multiline" >
					{{ticket.topic}}
				</div>
				<div class="ticket-detail-tags mytags">
					<a-tag class="mytag-tiny mytag-dash">
						{{$tenum('ticket.problem_type',ticket.problem_type)}}
					</a-tag>
					<template v-if="$notEmpty(ticket.search_tags)">
						<a-tag v-for="tag in ticket.search_tags" :key="tag" class="mytag-info mytag-tiny">
							{{tag}}
						</a-tag>
					</template>
				</div>
			</template>
		</a-table-column>
		<a-table-column
			:title="$t('ticket.field.ticket_types')" data-index="ticket.ticketTypes"
			:width="90">
			<template v-slot="types">
				{{getTicketTypes(types)}}
			</template>
		</a-table-column>
		<a-table-column
			key="event_datetime" :sorter="true"
			:title="$t('ticket.field.event.date')"
			:width="115"
			align="center"
			data-index="ticket.event_datetime">
			<template v-slot="text">
				{{$dayjs(text).format("D MMM YY")}} <br />
				<a-icon type="clock-circle" /> {{$dayjs(text).format("H:m")}}
			</template>
		</a-table-column>
		<a-table-column
			v-if="!hideColumnCompany"
			key="reportBy.company" :sorter="true"
			:title="$t('ticket.field.company')"
			data-index="reportBy"

			:width="225"
			align="center">
			<template v-slot="userData" >
				<span style="font-size:0.95em">
				{{userData.company.name}}
				</span>

			</template>
		</a-table-column>
		<a-table-column
			key="reportBy.username" :sorter="true"
			:title="$t('ticket.field.report_by')"
			data-index="reportBy"
			:width="125"
			align="center">
			<template v-slot="userData" >
				<my-router-link name="user/view" :param-value="userData.id" new-window>
					<ImageName :name="userData.user.username" size="very-tiny" :src="userData.user.image_url">
						<a-icon type="user" />
					</ImageName>
				</my-router-link>
			</template>
		</a-table-column>
		<a-table-column
			key="updated_datetime" :sorter="true"
			:width="150"
			:title="$t('ticket.field.updated')"
			align="center">
			<template v-slot="{ ticket,updatedBy: userData }" >
				<div>
				{{$dayjs(ticket.updated_datetime).format("D MMM YY")}}
				<a-icon type="clock-circle" /> {{$dayjs(ticket.updated_datetime).format("H:mm")}}
				</div>
				<div>
					{{$t('common.by')}}
					<my-router-link name="user/view" :param-value="userData.id" new-window>
						{{userData.user.username}}
					</my-router-link>
				</div>
			</template>
		</a-table-column>
		<a-table-column
			key="status" :sorter="true"
			:title="$t('ticket.field.status')"
			:width="100"
			align="center">
			<template v-slot="{ ticket }">
				<span class="ticket-table-small">
				<TicketStatus
					:status="ticket.status" :reason="ticket.status_reason"/>
				</span>
			</template>
		</a-table-column>
		<a-table-column
			:title="$t('ticket_table.field.resolved_result')"
			:width="100"
			align="center">
			<template v-slot="{ ticket }">
				<span  v-if="ticket.problem_type == 'consult'" class="ticket-table-small">
					{{$tenum('ticket.resolved_result',ticket.resolved_result,'')}}
				</span>
			</template>
		</a-table-column>
	</a-table>
</template>

<script>
import {Table,Tag} from "ant-design-vue"
import TicketStatus from "@components/ticket/TicketStatus.vue"
import ImageName from "@components/common/ImageName.vue"

export default {
	components : {
		ImageName,TicketStatus,
		"a-tag" : Tag,
		"a-table" : Table, "a-table-column" : Table.Column
	} ,
	props : {
		pagination : {
			type : null,
			default : () => []
		} ,
		hideColumns : {
			type : null,
			default : () => []
		}
	} ,
	computed : {
		hideColumnDrone() {
			return this.$notEmpty(this.hideColumns) && this.hideColumns.includes('drone')
		} ,
		hideColumnCompany() {
			return this.$notEmpty(this.hideColumns) && this.hideColumns.includes('company')
		}
	} ,
	methods :{
		getTicketTypes(types) {
			return (types) ? types.map((t)=>this.$tenum('ticket.ticket_type',t)).join(', ') : null
		}
	}
}
</script>

<style lang="less" scoped>
.ticket-table::v-deep table {
	min-width : 1200px;
	td.ticket-detail-col {
		min-width : 200px;
		font-size : 0.9em;
		vertical-align: top;
	}
}
.ticket-detail-tags {
	margin-top : 12px;
}
.drone-serial-no {
	display : block;
	font-size : 0.9em;
	margin-top : 2px;
}
.ticket-table-small {
	font-size : 0.9em;
}
</style>
