<i18n locale="th" lang="yaml" >
chart.name : "จำนวน Ticket (ตามวันที่เกิดเหตุ ย้อนหลัง 1 ปี) "
chart.summary : "มีจำนวน Ticket ทั้งหมด {total} @:common.ticket.unit, ขอคำปรึกษา {consult} @:common.ticket.unit"

chart.ticket.num_tickets : "Ticket"
chart.ticket.num_consult : "Consult"
</i18n>

<template>
	<ChartContainer
		ref="container"
		:loading="loading"
		:is-empty="total <= 0"
		:error-message="error"
		:summary="summary"
		v-bind="$attrs">
		<VerticalBarChart :hide-legend="false" :data="chartData" :chart-options="chartOptions"/>
		<span slot="title">
			{{$t('chart.name')}} <br/>
			<b>{{lotName}}</b>
		</span>
	</ChartContainer>
</template>

<script>
import axios from "axios"
import ChartContainer from "./general/ChartContainer.vue"
import VerticalBarChart from "./general/VerticalBarChart.vue"
import ApiError from '@utils/errors/ApiError'

export default {
	components : {
		ChartContainer , VerticalBarChart
	} ,
	props : {
		lotName : {
			type : String,
			default : undefined
		} ,
		lotId : {
			type : Number,
			default : 0,
		}
	} ,
	data() {
		return {
			total : 0,
			totalConsult : 0,
			loading : false,
			error : undefined,

			chartData: {},
			chartOptions: {}
		}
	} ,
	computed : {
		summary() {
			if(this.total <=0)
				return undefined;
			else
				return this.$t('chart.summary',{total : this.total,consult : this.totalConsult})
		}
	} ,
	watch :{
		lotId() {
			this.fetchData()
		}
	} ,
	mounted() {
		this.fetchData()
	} ,
	methods : {
		reset() {
			this.total = 0
			this.totalConsult =0
			this.error = null
			this.loading = false
		} ,
		convertDataset(dataset,monthLabels) {
			if (!this.$notEmpty(dataset))
				return null
			const labels = ['',...Object.values(monthLabels)]
			const statData = [0]
			const consultData = [0]

			for(const key in monthLabels) {
				if (key in dataset.num_tickets) {
					statData.push(dataset.num_tickets[key])
				} else {
					statData.push(0)
				}
				if (key in dataset.num_consults) {
					consultData.push(dataset.num_consults[key])
				} else {
					consultData.push(0)
				}
			}
			return {
				labels ,
				datasets : [
					{
						label: this.$t('chart.ticket.num_tickets'),
						backgroundColor : "#1890ff",
						borderColor :  "#1890ff",
						data : statData,
						fill : false,
					} ,
					{
						label: this.$t('chart.ticket.num_consult'),
						backgroundColor : "#ffc45d",
						borderColor :  "#ffc45d",
						data : consultData,
						fill : false,
					}
				]
			}
		} ,
		fetchData() {
			this.reset()
			if (this.lotId <= 0) {
				return;
			}
			const startDate = this.$dayjs().subtract(12,'month').format("YYYY-MM-DD");
			const endDate= this.$dayjs().format("YYYY-MM-DD");

			this.loading = true
			this.error = undefined
			axios.get("/api/tickets/stat-tickets-by-lots/"+this.lotId,{params : {start_date:startDate,end_date:endDate}}).then((response) => {
				this.total = response.data.data.total;
				this.totalConsult = response.data.data.totalConsult
				this.chartData = this.convertDataset(response.data.data.dataset,response.data.data.month_labels)
				this.chartOptions = {
					scales : {
						yAxes : [
							{ticks:{min : 0,precision : 0,suggestedMax:response.data.data.max_value+3}}
						]
					}
				}
			}).catch((error) => {
				this.error = ApiError.getDisplayErrorMessage(this,error)
			}).finally(() => {
				this.loading = false
			});
		}
	}
}
</script>
