export function droneStatusColors() {
	return {
		'new' : '#FFF176' ,
		'active' : '#81C784',
		'decommission' : '#E57373',
		'backup' : '#BDBDBD' ,
	}
}
export function ticketStatusColors() {
	return {
		'new' : '#1890ff',
		'investigate' : '#ffc425',
		'reopen' : '#81c2ff' ,
		'resolved' : '#f37735' ,
		'pending' : '#fcd97c',
		'close' : '#52c41a',
		'cancel' : '#E57373',
	}
}
export function serviceStatusColors() {
	return {
		'new' : '#1890ff',
		'in_progress' : '#ffc425',
		'pending' : '#fcd97c' ,
		'pre_delivery' : '#f37735',
		'wait_delivery' : '#f8aa80',
	}
}
