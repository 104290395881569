<i18n locale="th" lang="yaml" >
note.empty : "ไม่มี Note สำหรับ {name} นี้"
note.update : "ปรับ Note นี้"
note.clear : "ล้างค่า Note นี้"

note.modal.title : "Drone Note Editor"
</i18n>

<template>
	<div v-if="!hideEmpty || $notEmpty(note.detail_rich)" class="note-pane">
		<div v-if="$notEmpty(topic)" class="topic">{{topic}}</div>
		<div v-if="loading" class="loading">
			<a-spin />
		</div>
		<div v-else>
			<div v-if="$notEmpty(note.detail_rich)">
				<QuillViewer
					:content="note.detail_rich"/>
				<div class="note-info">
					<span>
						{{$t('common.by')}}
					</span>
					<my-router-link
						name="user/view" :param-value="user.id" :new-window="true">
						{{user.username}}
					</my-router-link>
					<span style="margin:'0 4px';">
						{{$t('common.from')}}
					</span>
					<my-router-link name="company/view" :param-value="company.id" auth-action="read" auth-module="company"
					:new-window="true">
						{{company.name}}
					</my-router-link>
					<DayFromNowText
						style="margin-left:4px"
						:value="note.updated_datetime"/>
				</div>
			</div>
			<div v-else class="note-pane-empty">
				<NoteIcon class="icon"/>
				<div class="empty-text">{{$t('note.empty',{ name: lotName })}}</div>
			</div>
			<a v-if="showEdit" class="note-action" @click="handleEditNote">
				<EditIcon /> {{$t('note.update')}}
			</a>
		</div>

		<a-modal
			v-if="showEdit"
			:visible="modalVisible"
			:title="$t('note.modal.title')"
			:ok-text="$t('common.save')"
			:ok-button-props="{ props: { loading: modalLoading } }"
			:cancel-button-props="{ props: { disabled: modalLoading } }"
			:mask-closable="false"
			@ok="handleModalOk"
			@cancel="handleModalCancel">
			<RichTextInput
				v-model="noteContentRich"
				class="note-editor"
				@changeText="handleNoteChange"/>

			<template slot="footer">
				<a-button key="delete" class="note-clear-btn" :disabled="modalLoading" type="danger" icon="delete" @click="handleModalClear">
					{{$t('note.clear')}}
				</a-button>
				<a-button key="close" :disabled="modalLoading" @click="handleModalCancel">
					{{$t('common.cancel')}}
				</a-button>
				<a-button key="ok" :loading="modalLoading" type="primary" @click="handleModalOk">
					{{$t('common.save')}}
				</a-button>
			</template>
		</a-modal>
	</div>
</template>

<script>
import axios from "axios"
import ApiError from "@utils/errors/ApiError"
import QuillViewer from "@components/common/QuillViewer.vue"
import NoteIcon from "vue-material-design-icons/NoteTextOutline.vue"
import EditIcon from "vue-material-design-icons/Pencil.vue"
import RichTextInput from "@components/input/RichTextInput.vue"
import {isStringEmpty as _isStringEmpty} from "@utils/stringUtil"
import DayFromNowText from "@components/common/DayFromNowText.vue"
export default {
	components : {
		QuillViewer , RichTextInput,
		NoteIcon,EditIcon,DayFromNowText
	} ,
	props : {
		lotName : {
			type : String,
			default : undefined,
		} ,
		lotId : {
			type : Number,
			default : 0,
		} ,
		editable : {
			type : Boolean,
			default : false,
		} ,
		hideEmpty : {
			type : Boolean,
			default : false,
		} ,
		topic : {
			type : String,
			default : undefined
		}
	} ,
	data() {
		return {
			note : {} ,
			user : {} ,
			company : {},
			loading : false,

			modalVisible : false,
			modalLoading : false,
			modalOkDisable : false,
			noteContentRich : '',
		}
	} ,
	computed :{
		showEdit() {
			return this.editable && this.$authorize('update', 'droneLot')
		}
	} ,
	watch : {
		lotId() {
			this.fetchData()
		}
	} ,
	mounted(){
		this.fetchData();
	} ,
	methods : {
		fetchData() {
			if (!this.lotId)
				return;
			this.loading = true
			axios.get("/api/drone-lots/"+this.lotId+"/note").then((response) => {
				if (response.data.data) {
					this.note = response.data.data.drone_lot_note
					this.user = response.data.data.updatedByData.user
					this.company = response.data.data.updatedByData.company
				} else {
					this.note = {}
					this.user = {}
					this.company = {}
				}
			}).catch((error)=>{
				this.$message.error(ApiError.getDisplayErrorMessage(this,error))
			}).finally(()=> {
				this.loading = false
			})
		} ,
		handleEditNote() {
			this.modalVisible = true
			this.noteContentRich = this.note.detail_rich
		} ,
		handleModalOk() {
			this.modalLoading = true
			const submitData = {
				detail_rich : this.noteContentRich
			}
			axios.post("/api/drone-lots/"+this.lotId+"/note",submitData).then((response)=>{
				if (response.data.data) {
					this.note = response.data.data.drone_lot_note
					this.user = response.data.data.updatedByData.user
					this.company = response.data.data.updatedByData.company
				} else {
					this.note = {}
					this.user = {}
					this.company = {}
				}
				this.handleModalCancel()
			}).catch((error) => {
				this.$message.error(ApiError.getDisplayErrorMessage(this,error))
			}).finally(()=>{
				this.modalLoading = false
			})
		},
		handleModalClear() {
			this.noteContentRich = ""
		},
		handleModalCancel() {
			this.modalVisible = false
			this.modalLoading = false
			this.noteContentRich = ''
		} ,
		handleNoteChange(text) {
			if (_isStringEmpty(text)) {
				this.noteContentRich = ''
			}
		}
	}
}
</script>

<style lang="less" scoped>
.note-pane {
	border : 1px solid @border-color-base;
	padding : 16px 16px 32px;
	border-radius : @border-radius-base;
	min-width : 275px;
	min-height : 150px;
	position: relative;


	.topic {
		font-weight: 600;
		color : @secondary-color;
		font-family: @font-family-title;
		font-size : 1.1em;
		text-decoration: underline;
	}
	.note-action {
		position : absolute;
		bottom : 8px;
		right : 16px;
		display : block;
		font-size : 0.9em;
		text-decoration: underline dashed;
	}
	.note-info {
		position : absolute;
		bottom : 8px;
		left : 16px;
		font-size : 0.9em;
		color : @text-muted;
	}
	.loading {
		position : absolute;
		top : 50%;
		left : 50%;
		transform : translate(-50%,-50%);
	}
}

.note-pane-empty {
	position : absolute;
	top : 50%;
	left : 50%;
	transform : translate(-50%,-50%);
	color : @text-muted;
	text-align : center;
	.icon {
		font-size : 4em;
		margin-bottom : 8px;
	}
}
</style>
<style lang="less">
.note-editor.richtext-editor  .ql-editor {
	min-height : 250px;
}
.note-clear-btn {
	position: absolute;
  left: 16px;
}
</style>
