<template>
	<div>
		<TicketTable
			bordered
			:row-key="record => record.id"
			:data-source="ticketDataList"
			:pagination="pagination"
			:loading="loading"
			:hide-columns="hideColumns"
			@change="handleTableChange"/>

		<div v-if="backLink.name" style="margin-top:24px">
			<a-button
				icon="left"
				@click="goBack">
				{{$t('common.back')}}
			</a-button>
		</div>
	</div>
</template>

<script>
import TicketTable from "@components/ticket/TicketTable.vue"
import ApiError from "@utils/errors/ApiError"
import axios from "axios"
import { fulfillTicket } from '@utils/objectUtil'
export default {
	components : {
		TicketTable,
	} ,
	props : {
		filterName : {
			type : String,
			default : undefined,
		} ,
		filterValue : {
			type : null,
			default : undefined,
		} ,
		backLink : {
			type : null,
			default : () => []
		} ,
		mode : {
			type : String,
			default : undefined
		} ,
		hideColumns : {
			type : null,
			default : () => []
		}
	} ,
	data() {
		return {
			ticketDataList : [],
			loading : false,
			pagination : {
				pageSize : 10 ,
				current : 1,
				total : 0,
				hideOnSinglePage : true,
			} ,
			sort : {
				field : 'updated_datetime',
				order : 'descend',
			} ,
		}
	} ,
	watch : {
		filterName(){
			this.fetchData()
		} ,
		filterValue() {
			this.fetchData()
		}
	},
	mounted() {
		this.fetchData();
	} ,
	methods : {
		fetchData(toPage=null) {
			if (this.filterName && !this.filterValue) {
				return;
			}
			if (toPage === null)
				toPage = this.pagination.current
			const search = {
				page_size : this.pagination.pageSize ,
				current_page : toPage,
				sort_field : this.sort.field ,
				sort_order : this.sort.order ,
			}
			const filter = {};
			if (this.filterName) {
				filter[this.filterName] = this.filterValue
			}
			if (this.mode === 'recent') {
				filter.status_list = ['close','resolved']
			} else if (this.mode === 'pending') {
				filter.status_list = ['pending']
			} else if (this.mode ==='progress') {
				filter.status_list = ['new','investigate','reopen']
			} else {
				return
			}

			this.loading = true;
			axios.get("/api/tickets/search",{params : {search,filter}}).then((response)=>{
				const page = response.data.data.pagination;
				const bucket = response.data.data.bucket

				this.ticketDataList = response.data.data.tickets.map((ticket)=>{
					return fulfillTicket(ticket,bucket)
				})

				this.pagination.current = toPage
				this.pagination.total = page.total
			}).catch((error) => {
				this.$message.error(ApiError.getDisplayErrorMessage(this,error))
			}).finally(()=>{
				this.loading = false
			})
		} ,
		handleTableChange(pagination,filters,sorter) {
			this.sort.field = sorter.columnKey;
			this.sort.order = sorter.order;
			this.fetchData(pagination.current);
		} ,
		goBack() {
			this.$open(this.backLink)
		}
	}
}
</script>
